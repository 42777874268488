import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Article } from "../../../interfaces/interfaces";
import { getAllArticles } from "../../../api/articlesApi";
import { useLocalState } from "../../../useLocalStorage";

const ListeArticles: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [user] = useLocalState("", "user");

  // Appel API au chargement du composant
  useEffect(() => {
    const fetchArticles = async () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.jwt}`,
      };
      const fetchedArticles = await getAllArticles(headers); // Appelle la fonction pour récupérer les articles
      setArticles(fetchedArticles); // Met à jour l'état avec la liste des articles
    };

    fetchArticles();
  }, []);
  return (
    <section id="ArticleBlog" className="bgDark">
      <div className="container py-5">
        <div className="row bgLight rounded my-5 py-3">
          <h1 className="text-center section-title fs-1">Liste des articles</h1>
          {articles.length === 0 ? (
            <div className="col-12 mt-4">
              <p>Aucun article trouvé</p>
            </div>
          ) : (
            articles.map((article) => (
              <div className="col-4 mt-4" key={article.id}>
                <div className="card text-center">
                  {article.image && (
                    <img
                      src={article.image}
                      alt={article.title}
                      className="card-img-top"
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">{article.title}</h5>
                    <p className="card-text">{article.intro}</p>
                    <Link
                      to={`/admin/modifierArticle/${article.id}`}
                      className="btn btn-success"
                    >
                      Modifier
                    </Link>
                  </div>
                  <div className="card-footer text-muted">
                    <div>
                      <strong>Par :</strong> {article.createdBy}
                    </div>
                    <div>
                      <strong>Dernière modification le :</strong>{" "}
                      {new Date(article.modifiedAt).toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};
export default ListeArticles;
