import React from 'react';
import './StatusMessage.css';

interface StatusMessageProps {
    message: string;
    type: 'success' | 'error';
}

const StatusMessage: React.FC<StatusMessageProps> = ({ message, type }) => {
    return (
        <div
            className={`status-message fixed-bottom p-2 rounded mt-2 text-center ${type}`}
            style={{
                opacity: 1,
                transition: 'opacity 0.5s ease-out',
            }}
        >
            {message}
        </div>
    );
};

export default StatusMessage;
