import React from 'react';
import { NouvelArticle } from '../../components/Admin/NouvelArticle';
const PageNouvelArticle: React.FC = () => {
    return (
      <>
        <NouvelArticle />
      </>
    );
  };
  
  export default PageNouvelArticle;