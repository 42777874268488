import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavbarAdmin.css';

const NavbarAdmin: React.FC = () => {
  const location = useLocation();
  return (
    <nav id="navbar" className={`navbar sticky-top navbar-expand-lg navbar-dark bgDark colorDark no-select`}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/images/GoodShi.png" alt="Logo" width="50" height="50" className="d-inline-block align-top" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/admin/listeArticles">Liste des articles</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/admin/nouvelArticle">Ajouter un article</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarAdmin;
