import React, { useState } from "react";
import { useLocalState } from "../../useLocalStorage";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { login } from "../../api/userApi";

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [user, setUser] = useLocalState("", "user");
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    const user = await login(username, password);
    console.log(user);
    if (typeof user !== "string") {
      setUser(JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      navigate("/admin/listeArticles");
    } else {
      console.error(error);
      setError("An error occurred during login.");
    }
  };

  return (
    <div className="auth-container">
      <div className="form-container">
        <h1>Connexion</h1>
        <form onSubmit={handleLogin}>
          <div>
            <label>Nom d'utilisateur:</label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Nom d'utilisateur"
            />
          </div>
          <div>
            <label>Mot de passe:</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
            />
          </div>
          {error && <div className="error">{error}</div>}
          <button id="loggingButton" type="submit">
            Connexion
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
