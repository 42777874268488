import React, { useState, useEffect } from "react";
import "./TagInput.css";
import { Tag, TagInputProps } from "../../../interfaces/interfaces";
import { getTags, addNewTag } from "../../../api/tagsApi";
import { useLocalState } from "../../../useLocalStorage";

const TagInput: React.FC<TagInputProps> = ({
  selectedTags,
  setSelectedTags,
}) => {
  const [tags, setTags] = useState<Tag[]>([]); // Liste des tags depuis la BDD
  const [searchTerm, setSearchTerm] = useState<string>(""); // Contenu de l'input
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false); // Gérer la visibilité de la liste déroulante
  const [user] = useLocalState("", "user");
  // Requête API pour récupérer les tags
  useEffect(() => {
    const fetchTags = async () => {
      if (searchTerm.length > 0) {
        const listeTags = await getTags(); // Attend la réponse de l'API
        if (listeTags.length > 0) {
          setTags(listeTags); // Si des tags sont récupérés, les ajouter à l'état
        } else {
          console.error("Aucun tag trouvé");
        }
      } else {
        setTags([]); // Réinitialise les suggestions si l'input est vide
      }
    };

    fetchTags(); // Appel de la fonction asynchrone
  }, [searchTerm]);

  // Ajouter un tag à la liste des tags choisis
  const addTag = (tag: Tag) => {
    // Vérifie si le tag est déjà sélectionné
    if (!selectedTags.some((selectedTag) => selectedTag.id === tag.id)) {
      setSelectedTags((prevTags) => [...prevTags, tag]); // Ajoute l'objet tag entier
    }
    setSearchTerm(""); // Réinitialise l'input après ajout
  };

  // Retirer un tag de la liste des tags choisis
  const removeTag = (tagId: number) => {
    // Filtre les tags pour enlever celui dont l'id correspond
    setSelectedTags((prevTags) => prevTags.filter((tag) => tag.id !== tagId));
  };
  const filteredTags = tags.filter(
    (tag) =>
      tag.nom.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedTags.some((selectedTag) => selectedTag.id === tag.id)
  );
  const handleAddTag = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwt}`,
    };
    const formTag: Tag = {
      id: 0, // Id généré par le backend
      nom: searchTerm,
    };
    const newTag = await addNewTag(formTag, headers); // Attend la réponse de l'API
    if (newTag) {
      addTag(newTag); // Si un tag est ajouté avec succès, l'ajoute à l'état
    } else {
      console.error("Erreur lors de l'ajout du tag");
    }
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // Utiliser un setTimeout pour permettre à l'élément sélectionné de garder le focus
    setTimeout(() => setDropdownVisible(false), 200); // 200ms pour permettre à l'utilisateur de cliquer sur un élément
  };
  return (
    <div className="tag-input">
      <label htmlFor="tagInput" className="form-label">
        Tags
      </label>
      <input
        id="tagInput"
        type="text"
        className="form-control"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setDropdownVisible(true)} // Affiche la liste déroulante
        onBlur={handleBlur}
      />
      {/* Liste déroulante */}
      {isDropdownVisible && searchTerm.length > 1 && (
        <ul className="dropdown-list">
          {filteredTags.map((tag) => (
            <li
              className="dropdown-item px-2"
              key={tag.id}
              onClick={() => addTag(tag)}
            >
              {tag.nom}
            </li>
          ))}
          <li className="dropdown-item px-2" onClick={() => handleAddTag()}>
            Ajouter un tag
          </li>
        </ul>
      )}

      {/* Affichage des tags sélectionnés */}
      <div className="selected-tags mt-2">
        {selectedTags.map((tag) => (
          <div key={tag.id} className="tag-badge border px-2 bg-white rounded">
            {tag.nom}
            <span
              className="text-danger ms-2"
              onClick={() => removeTag(tag.id)}
            >
              x
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
