// src/pages/PageRealisations.tsx
import React from 'react';
import { TermsOfUse } from '../components/TermsOfUse';
import { Footer } from '../components/Footer';
const PageCGU: React.FC = () => {
    return (
      <>
        <TermsOfUse />
        <Footer backgroundColor="bgDark colorDark" />
      </>
    );
  };
  
  export default PageCGU;