import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useLocalState } from "./useLocalStorage";

const PrivateRoute: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [user] = useLocalState("", "user");
  return user.jwt ? <>{children}</> : <Navigate to="/admin/login" />;
};

export default PrivateRoute;
