import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TagInput } from "../TagInput";
import { StatusMessage } from "../StatusMessage";
import { Article, Tag } from "../../../interfaces/interfaces";
import { formatDate, formatDateForInput } from "../../../utils/dateUtils";
import { injectHtmlToQuill } from "../../../utils/quillUtils";
import {
  updateArticle,
  getArticle,
  deleteArticle,
} from "../../../api/articlesApi";
import { useLocalState } from "../../../useLocalStorage";

const ModifierArticle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const articleId = id ? parseInt(id, 10) : NaN;
  const quillRef = React.useRef<ReactQuill>(null);

  const [article, setArticle] = useState<Article | null>(null);
  const [texte, setText] = useState<string>("");
  const [intro, setIntro] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [htmlInput, setHtmlInput] = useState<string>("");
  const [tags, setTags] = useState<Tag[]>([]);
  const [articleDate, setArticleDate] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [createdBy, setCreatedBy] = useState<string>("");
  const [modifiedAt, setModifiedAt] = useState<string>("");
  const [modifiedBy, setModifiedBy] = useState<string>("");

  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [statusType, setStatusType] = useState<"success" | "error" | null>(
    null
  );
  const [user] = useLocalState("", "user");

  const navigate = useNavigate();

  useEffect(() => {
    if (articleId) {
      const fetchArticle = async () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.jwt}`,
        };
        const fetchedArticle = await getArticle(articleId, headers); // Appelle la fonction pour récupérer l'article
        if (fetchedArticle) {
          setArticle(fetchedArticle); // Met à jour l'état avec l'article récupéré
        }
      };
      fetchArticle();
    }
  }, [articleId]);

  useEffect(() => {
    if (article) {
      setImage(article.image);
      setTitle(article.title);
      setIntro(article.intro);
      setText(article.texte);
      setArticleDate(article.date);
      setCreatedAt(article.createdAt);
      setCreatedBy(article.createdBy);
      setModifiedAt(article.modifiedAt);
      setModifiedBy(article.modifiedBy);
      setTags(article.tags);
    }
  }, [article]);

  const handleContentChange = (value: string) => setText(value);

  const handleInsertHtml = () => {
    injectHtmlToQuill(quillRef, htmlInput);
    setHtmlInput("");
  };
  const handleSubmit = async () => {
    const currentDate = new Date().toISOString();
    const formData: Article = {
      id: articleId,
      date: articleDate,
      image: image,
      title: title,
      intro: intro,
      texte: texte,
      createdBy: createdBy,
      modifiedBy: modifiedBy,
      createdAt: currentDate,
      modifiedAt: currentDate,
      tags: tags,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwt}`,
    };
    const isUpdated = await updateArticle(articleId, formData, headers); // Appelle la fonction pour mettre à jour l'article

    if (isUpdated) {
      setStatusMessage("Article mis à jour avec succès!");
      setStatusType("success");
    } else {
      setStatusMessage("Une erreur est survenue lors de la mise à jour.");
      setStatusType("error");
    }

    setTimeout(() => setStatusMessage(null), 3000); // Cache le message après 3 secondes
  };
  const handleDelete = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwt}`,
    };
    const isUpdated = await deleteArticle(articleId, headers); // Appelle la fonction pour supprimer l'article

    if (isUpdated) {
      setStatusMessage("Article supprimé avec succès!");
      setStatusType("success");
    } else {
      setStatusMessage("Une erreur est survenue lors de la suppression.");
      setStatusType("error");
    }

    setTimeout(() => {
      setStatusMessage(null); // Cache le message
      navigate("/admin/listeArticles"); // Redirige vers la page des articles (vous pouvez changer l'URL de redirection selon votre besoin)
    }, 3000);
  };

  return (
    <section id="ArticleBlog" className="bgDark">
      <div className="container py-5">
        <div className="row bgLight colorLight rounded my-5 py-3">
          <h1 className="text-center section-title fs-1">
            Modification d'un article
          </h1>
          {article ? (
            <>
              <div className="col-12">
                <label htmlFor="inputImage" className="form-label">
                  Image
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputImage"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                />
                <label htmlFor="inputTitle" className="form-label">
                  Titre
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputTitle"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                <label htmlFor="textareaIntro" className="form-label mt-3">
                  Introduction
                </label>
                <textarea
                  id="textareaIntro"
                  className="form-control"
                  rows={3}
                  value={intro}
                  onChange={(e) => setIntro(e.target.value)}
                />

                <label htmlFor="contentEditor" className="form-label mt-3">
                  Texte
                </label>
                <ReactQuill
                  ref={quillRef}
                  id="contentEditor"
                  theme="snow"
                  value={texte}
                  onChange={handleContentChange}
                  placeholder="Ajoutez du contenu enrichi ici..."
                  className="bg-white"
                />

                <label htmlFor="htmlTextarea" className="form-label mt-3">
                  Coller du HTML
                </label>
                <textarea
                  id="htmlTextarea"
                  className="form-control"
                  rows={5}
                  value={htmlInput}
                  onChange={(e) => setHtmlInput(e.target.value)}
                />
                <button
                  className="btn btn-secondary mt-2"
                  onClick={handleInsertHtml}
                >
                  Charger dans l'éditeur
                </button>

                <div className="mt-3">
                  <label className="form-label">Publication</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formatDateForInput(articleDate)}
                    onChange={(e) => setArticleDate(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <label className="form-label">Rédacteur</label>
                  <select
                    className="form-control"
                    onChange={(e) => setCreatedBy(e.target.value)}
                    value={createdBy}
                  >
                    <option value="Julien Degruson">Julien Degruson</option>
                    <option value="Julien Alfandari">Julien Alfandari</option>
                    <option value="Intelligence Artificielle">
                      Intelligence Artificielle
                    </option>
                  </select>
                </div>
              </div>
              <div className="mt-3">
                <TagInput
                  selectedTags={tags}
                  setSelectedTags={setTags} // Permet à TagInput de mettre à jour les tags dans le formulaire principal
                />
              </div>
              <div className="mt-3">
                <label className="form-label">Création</label>
                <input
                  type="text"
                  className="form-control"
                  value={formatDate(createdAt) + " par " + createdBy}
                  readOnly
                />
              </div>
              <div className="mt-3">
                <label className="form-label">Dernière modification</label>
                <input
                  type="text"
                  className="form-control"
                  value={formatDate(modifiedAt) + " par " + modifiedBy}
                  readOnly
                />
              </div>
              <div className="col-12 mt-4">
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Modifier l'article
                </button>
              </div>
              <div className="col-12 mt-4">
                <a href="/admin/listeArticles">
                  <button className="btn btn-secondary">Retour</button>
                </a>
              </div>
              <div className="col-12 mt-4">
                <button className="btn btn-danger" onClick={handleDelete}>
                  Supprimer l'article
                </button>
              </div>
            </>
          ) : (
            <div className="col-12">
              <p>Chargement...</p>
            </div>
          )}
        </div>
        {statusMessage && statusType && (
          <StatusMessage message={statusMessage} type={statusType} />
        )}
      </div>
    </section>
  );
};

export default ModifierArticle;
