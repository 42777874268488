// TODO : vider les champs après le succès
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TagInput } from "../TagInput";
import { StatusMessage } from "../StatusMessage";
import { Article, Tag } from "../../../interfaces/interfaces";
import { formatDateForInput } from "../../../utils/dateUtils";
import { injectHtmlToQuill } from "../../../utils/quillUtils";
import { createArticle } from "../../../api/articlesApi";
import { useLocalState } from "../../../useLocalStorage";

const NouvelArticle: React.FC = () => {
  const quillRef = React.useRef<ReactQuill>(null);
  const [image, setImage] = useState<string>("");
  const [texte, setText] = useState<string>("");
  const [intro, setIntro] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [htmlInput, setHtmlInput] = useState<string>("");
  const [tags, setTags] = useState<Tag[]>([]);
  const currentDate = new Date().toISOString();
  const [articleDate, setArticleDate] = useState<string>(currentDate);
  const [createdBy, setCreatedBy] = useState<string>("GoodShi");

  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [statusType, setStatusType] = useState<"success" | "error" | null>(
    null
  );
  const [user] = useLocalState("", "user");

  const handleContentChange = (value: string) => {
    setText(value); // Mettez à jour l'état avec le contenu HTML
  };
  const handleSubmit = async () => {
    const formArticle: Article = {
      id: 0,
      date: articleDate,
      image: image,
      title: title,
      intro: intro,
      texte: texte,
      createdBy: createdBy,
      modifiedBy: createdBy,
      createdAt: currentDate,
      modifiedAt: currentDate,
      tags: tags,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user.jwt}`,
    };
    const data = await createArticle(formArticle, headers); // Appelle la fonction pour créer l'article

    if (data) {
      setStatusMessage("Article ajouté avec succès!");
      setStatusType("success");
      setImage(""); // Réinitialise l'image
      setText(""); // Réinitialise le texte
      setIntro(""); // Réinitialise l'introduction
      setTitle(""); // Réinitialise le titre
      setHtmlInput(""); // Réinitialise le champ HTML
      setTags([]); // Réinitialise les tags
      setArticleDate(currentDate); // Réinitialise la date
    } else {
      setStatusMessage("Une erreur est survenue lors de la mise à jour.");
      setStatusType("error");
    }

    setTimeout(() => setStatusMessage(null), 3000); // Cache le message après 3 secondes
  };
  const handleInsertHtml = () => {
    injectHtmlToQuill(quillRef, htmlInput);
    setHtmlInput("");
  };
  return (
    <section id="ArticleBlog" className="bgDark">
      <div className="container py-5">
        <div className="row bgLight colorLight rounded my-5 py-3">
          <h1 className="text-center section-title fs-1">
            Rédaction d'un nouvel article
          </h1>
          <div className="col-12">
            <label htmlFor="inputImage" className="form-label">
              Image
            </label>
            <input
              type="text"
              className="form-control"
              id="inputImage"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            />

            <label htmlFor="inputTitle" className="form-label">
              Titre
            </label>
            <input
              type="text"
              className="form-control"
              id="inputTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <label htmlFor="textareaIntro" className="form-label mt-3">
              Introduction
            </label>
            <textarea
              id="textareaIntro"
              className="form-control"
              rows={3}
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
            />

            <label htmlFor="contentEditor" className="form-label mt-3">
              Texte
            </label>
            <ReactQuill
              ref={quillRef}
              id="contentEditor"
              theme="snow"
              value={texte}
              onChange={handleContentChange}
              placeholder="Ajoutez du contenu enrichi ici..."
              className="bg-white"
            />
            <label htmlFor="htmlTextarea" className="form-label mt-3">
              Coller du HTML
            </label>
            <textarea
              id="htmlTextarea"
              className="form-control"
              rows={5}
              value={htmlInput}
              onChange={(e) => setHtmlInput(e.target.value)}
            />
            <button
              className="btn btn-secondary mt-2"
              onClick={handleInsertHtml}
            >
              Charger dans l'éditeur
            </button>
          </div>
          <div className="mt-3">
            <label className="form-label">Publication</label>
            <input
              type="date"
              className="form-control"
              value={formatDateForInput(articleDate)}
              onChange={(e) => setArticleDate(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <label className="form-label">Rédacteur</label>
            <select
              className="form-control"
              onChange={(e) => setCreatedBy(e.target.value)}
              value={createdBy}
            >
              <option value="Julien Degruson">Julien Degruson</option>
              <option value="Julien Alfandari">Julien Alfandari</option>
              <option value="Intelligence Artificielle">
                Intelligence Artificielle
              </option>
            </select>
          </div>
          <div className="mt-3">
            <TagInput
              selectedTags={tags}
              setSelectedTags={setTags} // Permet à TagInput de mettre à jour les tags dans le formulaire principal
            />
          </div>
          <div className="col-12 text-center mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Publier l'article
            </button>
          </div>
        </div>
        {statusMessage && statusType && (
          <StatusMessage message={statusMessage} type={statusType} />
        )}
      </div>
    </section>
  );
};

export default NouvelArticle;
