import React from 'react';
import { ModifierArticle } from '../../components/Admin/ModifierArticle';
const PageModifierArticle: React.FC = () => {
    return (
      <>
        <ModifierArticle />
      </>
    );
  };
  
  export default PageModifierArticle;