import { User } from '../interfaces/interfaces';
import axios from "axios";
import { useLocalState } from "../useLocalStorage";


export const login = async (username :string, password :string): Promise<User | string> => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`,
          {username:username, password:password},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const user:User = response.data;
        const authorization = response.headers.authorization;
        if (authorization) {
            user.jwt = authorization;
          
        } else {
          throw new Error("Authorization header is missing");
        }

        return user;
      } catch (error) {
        var errorMessage;
        if (axios.isAxiosError(error)) {
            errorMessage = (error.response?.data || error.message);
        } else {
            errorMessage = ((error as Error).message);
        }

        return errorMessage;
      }
};

export const register = async (user :User): Promise<string | undefined> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/register`,
      user
    );
    alert(response.data);
  } catch (error) {
    var errorMessage;
        if (axios.isAxiosError(error)) {
            errorMessage = (error.response?.data || error.message);
        } else {
            errorMessage = ((error as Error).message);
        }

        return errorMessage;
  }
};