// src/pages/PageRealisations.tsx
import React from 'react';
import { Contact } from '../components/Contact';
import { Footer } from '../components/Footer';
const PageContact: React.FC = () => {
    return (
      <>
        <Contact />
        <Footer backgroundColor="bgDark colorDark" />
      </>
    );
  };
  
  export default PageContact;