import { Article } from '../interfaces/interfaces';

export const createArticle = async (articleData: Article, headers: any): Promise<Article | null> => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/blog-articles`;

    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(articleData),
        });

        if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        return data; // Renvoie l'article créé
    } catch (error) {
        console.error("Erreur lors de la requête:", error);
        return null; // Retourne null en cas d'erreur
    }
};

export const updateArticle = async (articleId: number, articleData: Article, headers: any): Promise<boolean> => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/blog-articles/${articleId}`;

    try {
        const response = await fetch(endpoint, {
            method: "PUT",
            headers: headers,
            body: JSON.stringify(articleData),
        });

        if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
        }

        return true; // L'article a été mis à jour avec succès
    } catch (error) {
        console.error("Erreur lors de la requête:", error);
        return false; // Retourne false si une erreur se produit
    }
};
export const getArticle = async (articleId: number, headers: any) => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/blog-articles/${articleId}`;

    try {
        const response = await fetch(endpoint, {
            headers: headers,
        });
        if (!response.ok) throw new Error(`Erreur HTTP: ${response.status}`);
        const data = await response.json();
        return data; // Retourne les données de l'article
    } catch (error) {
        console.error("Erreur lors de la récupération de l'article:", error);
        return null; // Retourne null en cas d'erreur
    }
};
export const getAllArticles = async (headers: any): Promise<Article[]> => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/blog-articles/all`;

    try {
        const response = await fetch(endpoint, {
            method: "GET",
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data: Article[] = await response.json(); // Parse la réponse JSON
        return data; // Retourne les données récupérées
    } catch (error) {
        console.error("Erreur lors de la requête :", error);
        return []; // Retourne un tableau vide en cas d'erreur
    }
};
export const deleteArticle = async (articleId: number, headers:any): Promise<boolean> => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/blog-articles/${articleId}`;

    try {
        const response = await fetch(endpoint, {
            method: "DELETE",
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
        }

        return true; // L'article a été supprimé avec succès
    } catch (error) {
        console.error("Erreur lors de la requête:", error);
        return false; // Retourne false si une erreur se produit
    }
};