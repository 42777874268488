import React, { useState } from "react";
import axios from "axios";
import "./RegisterPage.css";
import { register } from "../../api/userApi";
import { User } from "../../interfaces/interfaces";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    username: "",
    password: "",
    email: "",
    roles: [],
  });
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userData: User = {
      name: formData.name,
      surname: formData.surname,
      username: formData.username,
      password: formData.password,
      email: formData.email,
      jwt: "",
      roles: formData.roles, // Assurez-vous que c'est un tableau vide ou un tableau valide
    };
    const error = await register(userData);
    if (error) {
      setError(error);
    }
  };

  return (
    <div className="auth-container">
      <div className="form-container">
        <h1>Inscription</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Prénom:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Nom:</label>
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Nom d'utilisateur:</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Mot de passe:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          {error && <div className="error">{error}</div>}
          <button type="submit">S'inscrire</button>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
