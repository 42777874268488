export const formatDate = (dateString: string) => {
    const date = new Date(Date.parse(dateString)); // Utiliser Date.parse() explicitement
    if (isNaN(date.getTime())) {
        return "Date invalide"; // En cas d'erreur de format de la date
    }

    return new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date);
};
export const formatDateForInput = (dateString: string) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
};
export const formatDateForWeb = (dateString: string) => {
    const date = new Date(Date.parse(dateString)); // Utiliser Date.parse() explicitement
    if (isNaN(date.getTime())) {
        return "Date invalide"; // En cas d'erreur de format de la date
    }

    return new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    }).format(date);
};