import React from 'react';
import { ListeArticles } from '../../components/Admin/ListeArticles';
const PageListeArticles: React.FC = () => {
    return (
      <>
        <ListeArticles />
      </>
    );
  };
  
  export default PageListeArticles;