import { Tag } from '../interfaces/interfaces';

// Fonction pour récupérer tous les tags
export const getTags = async (): Promise<Tag[]> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tags`);
        if (!response.ok) throw new Error("Erreur lors de la récupération des tags");
        const data: Tag[] = await response.json();
        return data;
    } catch (error) {
        console.error("Erreur:", error);
        return []; // Retourne un tableau vide en cas d'erreur
    }
};

// Fonction pour ajouter un tag
export const addNewTag = async (newTag: Tag, headers:any): Promise<Tag | undefined> => {
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/tags`;

    try {
        const response = await fetch(endpoint, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(newTag), // Convertit les données en JSON
        });

        if (!response.ok) {
            throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data: Tag = await response.json(); // Parse la réponse JSON
        console.log("Réponse du serveur:", data);
        return data;
    } catch (error) {
        console.error("Erreur lors de la requête :", error);
        return undefined; // En cas d'erreur, retourne undefined
    }
};