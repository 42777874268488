import { Quill } from 'react-quill'; // Assure-toi d'avoir installé react-quill

// Fonction pour initialiser un éditeur Quill
export const initializeQuill = (quillRef: React.RefObject<any>, config: object = {}) => {
    if (!quillRef.current) return;

    const quill = quillRef.current.getEditor();
    // Appliquer des configurations personnalisées à Quill
    quill.setContents([{ insert: 'Type your content here...' }]);
    // Exemple de configuration additionnelle
    // quill.getModule('toolbar').addHandler('bold', () => console.log('Bold clicked'));

    return quill;
};

// Fonction pour insérer du HTML dans l'éditeur
export const injectHtmlToQuill = (quillRef: React.RefObject<any>, htmlInput: string) => {
    if (!quillRef.current) return; // Vérifie que l'éditeur est initialisé

    const quill = quillRef.current.getEditor(); // Récupère l'instance Quill
    const currentLength = quill.getLength(); // Récupère la longueur actuelle du contenu
    quill.clipboard.dangerouslyPasteHTML(currentLength - 1, htmlInput); // Insère à la fin
};

// Fonction pour récupérer le contenu actuel de Quill
export const getQuillContent = (quillRef: React.RefObject<any>): string => {
    if (!quillRef.current) return '';
    const quill = quillRef.current.getEditor();
    return quill.root.innerHTML; // Récupère le contenu HTML de l'éditeur
};

// Fonction pour vider le contenu de l'éditeur
export const clearQuillEditor = (quillRef: React.RefObject<any>) => {
    if (!quillRef.current) return;
    const quill = quillRef.current.getEditor();
    quill.setText(''); // Vide le contenu de l'éditeur
};
